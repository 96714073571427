import { ServicePrice } from '../type/service-price'

const SOFT_FURNITURE_PRICE_LIST_HEADER_ROWS = ['Цени за пране на мека мебел:']
const SOFT_FURNITURE_PRICE_LIST: ServicePrice[] = [
  { service: 'Седящо място', price: 15 },
  { service: 'Табуретка', price: 10 },
  { service: 'Стол', price: 8 },
  { service: 'Фотьойл', price: 25 },
]

const MATTRESSES_HEADER_ROWS = ['Цени за пране на матрак:']
const MATTRESSES_PRICE: ServicePrice[] = [
  { service: 'Матрак – 1 персон еднолицев', price: 25 },
  { service: 'Матрак – 1 персон двулицев', price: 40 },
  { service: 'Матрак 2 персона (еднолицев)', price: 40 },
  { service: 'Матрак 2 персона (двулицев)', price: 70 },
]

const LEATHER_ROWS = ['Цени за кожени мебели:']
const LEATHER_PRICE: ServicePrice[] = [
  { service: 'Седящо място', price: 'от 20' },
  { service: 'Фотьойл', price: 'от 40' },
  { service: 'Офис стол', price: 'от 30' },
]

const ADDITIONAL_SERVICES = ['Други Услуги']
const ADDITIONAL_SERVICES_PRICE: ServicePrice[] = [
  { service: 'Почистване на прозорци', price: 'Цена след оглед', unit: '' },
  { service: 'Основно почистване', price: 'от 4', unit: 'лв/м²' },
  { service: 'Почистване след ремонт', price: 'от 5', unit: 'лв/м²' },
  { service: 'Пране на автомобилни седалки', price: '15', unit: 'лв/бр.' },
]

export {
  SOFT_FURNITURE_PRICE_LIST,
  SOFT_FURNITURE_PRICE_LIST_HEADER_ROWS,
  MATTRESSES_PRICE,
  MATTRESSES_HEADER_ROWS,
  LEATHER_ROWS,
  LEATHER_PRICE,
  ADDITIONAL_SERVICES,
  ADDITIONAL_SERVICES_PRICE,
}
