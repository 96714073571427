import { ReactNode } from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'
import { BlogMetadata } from '../../../common/type/blog-metadata'
import { Helmet } from 'react-helmet'

import './style.css'

type Prop = BlogMetadata & { children?: ReactNode }

const BlogPost = ({
  title,
  image,
  readInMin,
  postDate,
  children,
  to,
  description,
}: Prop) => {
  const baseUrl = 'https://www.martyscleaning.bg'
  const urlBlogPost = `${baseUrl}${to}`
  const srcSocial = `${baseUrl}${image.srcSocial}`

  return (
    <main className="blog-post">
      <Helmet>
        <meta name="description" content={description ?? image.alt} />
        <meta name="image" content={srcSocial} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description ?? image.alt} />
        <meta name="twitter:image" content={srcSocial} />
        <meta name="twitter:image:alt" content={image.alt} />
        <meta name="twitter:title" content={title} />
        <meta property="og:description" content={description ?? image.alt} />
        <meta property="og:image" content={srcSocial} />
        <meta property="og:image:alt" content={image.alt} />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={urlBlogPost} />
      </Helmet>
      <h1 className="blog-post__title">{title}</h1>
      <p className="blog-post__subtitle">{`${postDate.toLocaleString('bg', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}, ${readInMin} мин четене`}</p>
      <section className="blog-post__section">
        <div className="blog-post__section__socials">
          <FacebookShareButton
            url={urlBlogPost}
            className="blog-post__section__socials__fb"
          >
            <FacebookIcon size={32} round></FacebookIcon>
          </FacebookShareButton>
          <TwitterShareButton
            url={urlBlogPost}
            className="blog-post__section__socials__fb"
          >
            <XIcon size={32} round></XIcon>
          </TwitterShareButton>
        </div>
        <img
          className="blog-post__section__img"
          src={image.src}
          alt={image.alt}
          width={960}
          height={480}
        />
        {image?.author && (
          <div className="blog-post__section__photograph">
            {`Източник: `}
            <a href={image.author.url} target="_blank" rel="noreferrer">
              {image.author.name}
            </a>
          </div>
        )}
        {children}
      </section>
    </main>
  )
}

export default BlogPost
