import './style.css'

interface Prop {
  src: string
  alt: string
}

const ResponsiveImage = ({ src, alt }: Prop) => {
  return <img src={src} alt={alt} className="wrapper" loading="lazy" />
}

export default ResponsiveImage
