import React from 'react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import ReactDOM from 'react-dom/client'

import App from './components/container/app'
import Blog from './components/container/blog'
import BlogPost from './components/framework/blog-post'
import { blogPostMetadata } from './common/factory/blog-post-metadata'
import { BlogRoute } from './routes/blog'
import { Root } from './routes/root'
import FishingImg from './style/assets/IMG_0130.webp'
import ResponsiveImage from './components/framework/responsive-image'
// import UnderConstruction from './components/container/under-construction'
import reportWebVitals from './reportWebVitals'
import './index.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <div>Error</div>,
    children: [
      {
        index: true,
        element: <Navigate to="/home" />,
      },
      {
        path: '/home',
        element: <App />,
      },
      {
        path: '/blog',
        element: <BlogRoute />,
        children: [
          {
            path: '/blog/all',
            element: <Blog />,
          },
          {
            path: '/blog/polzite-ot-redovnoto-prane-na-matraci',
            element: (
              <BlogPost
                title={blogPostMetadata[0].title}
                postDate={blogPostMetadata[0].postDate}
                readInMin={blogPostMetadata[0].readInMin}
                image={blogPostMetadata[0].image}
                to={blogPostMetadata[0].to}
                description={blogPostMetadata[0].description}
                children={[
                  <p key={'pa1'}>
                    Редовното перене на матраца е важно за запазване на
                    хигиената във вашата спалня и за предпазване на здравето ви.
                    В матраците се задържат много насекоми, като прашец, кърлежи
                    и др., както и различни алергени, като прашец, мухъл и
                    други, които могат да предизвикат алергични реакции или да
                    допринесат за развитието на дихателни заболявания.
                  </p>,
                  <p key={'pa2'}>
                    Перенето на матраца премахва тези натрупвания от насекоми и
                    алергени, които могат да допринесат за здравословните ви
                    проблеми. Освен това, редовното перене на матраца може да
                    удължи живота й, като се премахнат мръсотията и
                    натрупванията, които могат да доведат до износване и повреди
                    на матраците.
                  </p>,
                  <p key={'pa3'}>
                    Като цяло, перенето на матраца на всеки 6 месеца е
                    препоръчително, но ако имате алергии или дихателни
                    затруднения, може да бъде необходимо да перете матраците
                    по-често.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/polzite-ot-osnovnoto-pochistvane-na-doma',
            element: (
              <BlogPost
                title={blogPostMetadata[1].title}
                postDate={blogPostMetadata[1].postDate}
                readInMin={blogPostMetadata[1].readInMin}
                image={blogPostMetadata[1].image}
                to={blogPostMetadata[1].to}
                description={blogPostMetadata[1].description}
                children={[
                  <p key={'pb1'}>
                    Почистването в дома е важно за поддържане на здравословна и
                    хигиенична среда, която може да помогне за предпазване на
                    здравето на всички, които живеят в дома.
                  </p>,
                  <p key={'pb2'}>
                    В дома има много микроби и бактерии, които могат да доведат
                    до различни заболявания, особено ако са натрупани в големи
                    количества. Почистването на повърхностите, като например
                    масите, бреговете, подовете и банята, може да помогне за
                    премахване на тези микроби и бактерии.
                  </p>,
                  <p key={'pb3'}>
                    Освен това, почистването може да предпази от алергени, като
                    прашец, кърлежи и др. Тези алергени могат да доведат до
                    различни алергични реакции, като астма, насморк и други.
                    Редовното почистване на дома може да намали наличието на
                    тези алергени и да предпази от алергични реакции.
                  </p>,
                  <p key={'pb4'}>
                    Като цяло, почистването в дома е важен елемент от
                    поддържането на здравословен начин на живот, който може да
                    помогне за предпазване на здравето на всички, които живеят в
                    дома.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/polzite-ot-profesionalnoto-pochistvane-na-doma',
            element: (
              <BlogPost
                title={blogPostMetadata[2].title}
                postDate={blogPostMetadata[2].postDate}
                readInMin={blogPostMetadata[2].readInMin}
                image={blogPostMetadata[2].image}
                to={blogPostMetadata[2].to}
                description={blogPostMetadata[2].description}
                children={[
                  <p key={'pc1'}>
                    Изборът за почистване на дома е индивидуален и може да
                    включва редица фактори като наличието на свободно време,
                    нивото на почистване, размера на дома и т.н. Въпреки това,
                    ако търсите професионално почистване на дома, има редица
                    предимства, които може да се вземат предвид.
                  </p>,
                  <p key={'pc2'}>
                    Предимства на професионалното почистване на дома
                  </p>,
                  <p key={'pc3'}>
                    Качествено почистване: Професионалните фирми за почистване
                    обикновено имат опитен и обучен персонал, който знае как да
                    почисти всякакъв вид повърхности и предмети в дома. Те
                    разполагат с необходимите инструменти и почистващи средства,
                    за да постигнат оптимални резултати.
                  </p>,
                  <p key={'pc4'}>
                    Икономия на време и усилия: Почистването на дома може да
                    отнеме много време и усилия, особено ако живеете в голям
                    дом. Професионалните фирми за почистване могат да ви
                    освободят от това задължение и да ви позволят да се
                    занимавате с други важни задачи.
                  </p>,
                  <p key={'pc5'}>
                    Подобряване на качеството на въздуха в дома: Професионалните
                    фирми за почистване могат да използват почистващи средства,
                    които помагат за премахване на алергени и микроби от въздуха
                    и повърхностите. Това може да подобри качеството на въздуха
                    в дома и да намали наличието на замърсявания, което може да
                    допринесе за предпазване на здравето на живеещите в него.
                  </p>,
                  <p key={'pc6'}>
                    Гъвкавост и удобство: Професионалните фирми за почистване
                    обикновено предлагат гъвкави графици и различни пакети за
                    почистване, които може да се адаптират към нуждите и бюджета
                    на клиента. Това може да предостави голямо удобство и
                    гъвкавост в зависимост от вашите нужди.
                  </p>,
                  <p key={'pc7'}>
                    В заключение, професионалното почистване на дома може да
                    бъде много полезно за всеки, който търси оптимални резултати
                    и удобство. Въпреки това, преди да изберете професионална
                    фирма за почистване, е важно да извършите изследвания и да
                    изберете надежден и качествен доставчик. Потърсете препоръки
                    от приятели и познати, прочетете отзиви в интернет или
                    направете допълнителни проверки за лицензи и сертификати на
                    фирмата.
                  </p>,
                  <p key={'pc8'}>
                    Всички тези мерки могат да ви помогнат да изберете
                    най-добрата професионална фирма за почистване на дома ви.
                    Накрая, внимателното избиране на професионална фирма за
                    почистване на дома ви може да ви спести много време, усилия
                    и да ви осигури оптимално чист и здравословен дом.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/kakvo-ni-otlichava-ot-konkurenciqta',
            element: (
              <BlogPost
                title={blogPostMetadata[3].title}
                postDate={blogPostMetadata[3].postDate}
                readInMin={blogPostMetadata[3].readInMin}
                image={blogPostMetadata[3].image}
                to={blogPostMetadata[3].to}
                description={blogPostMetadata[3].description}
                children={[
                  <p key={'pd1'}>
                    Мартисклийнинг е професионална фирма за почистване, която
                    може да ви предложи множество полезни услуги за почистване
                    на дома ви. Ето някои от причините да изберете
                    Мартисклийнинг:
                  </p>,
                  <p key={'pd2'}>
                    Качествено почистване: Мартисклийнинг използва
                    висококачествени почистващи продукти и професионално
                    оборудване, за да ви осигури оптимално чист дом. Екипът на
                    Мартисклийнинг е професионален и опитен в почистването на
                    различни видове повърхности и материали.
                  </p>,
                  <p key={'pd3'}>
                    Гъвкавост: Мартисклийнинг може да предложи гъвкави решения
                    за почистване, които отговарят на вашите специфични нужди и
                    желания. Независимо дали искате редовно почистване на дома
                    си или специализирани услуги като почистване на килими,
                    мебели или дълбоко почистване на кухненски шкафове и фурни,
                    Мартисклийнинг може да ви предложи решение, което отговаря
                    на вашите нужди.
                  </p>,
                  <p key={'pd4'}>
                    Удобство: Избирането на Мартисклийнинг за почистване на дома
                    ви може да ви спести много време и усилия. Вие можете да се
                    наслаждавате на чист дом без да се тревожите за почистването
                    и да имате повече време за други дейности.
                  </p>,
                  <p key={'pd5'}>
                    Сигурност: Мартисклийнинг е лицензирана и застрахована
                    фирма, която може да ви осигури сигурността, че вашият дом и
                    имуществото ви са защитени по време на почистването. Екипът
                    на Мартисклийнинг е обучен да работи в безопасност и да
                    използва подходяща защитна екипировка.
                  </p>,
                  <p key={'pd6'}>
                    Отзивчивост: Мартисклийнинг е отзивчива и грижовна фирма,
                    която се стреми да осигури на клиентите си оптимално
                    удовлетворение. В случай на проблем или въпрос, екипът на
                    Мартисклийнинг е готов да ви помогне и да намери най-доброто
                    решение за вас.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/10-prichini-poradi-koito-da-perete-redovno-divana-si',
            element: (
              <BlogPost
                title={blogPostMetadata[4].title}
                postDate={blogPostMetadata[4].postDate}
                readInMin={blogPostMetadata[4].readInMin}
                image={blogPostMetadata[4].image}
                to={blogPostMetadata[4].to}
                description={blogPostMetadata[4].description}
                children={[
                  <p key={'pe1'}>
                    Ето 10 причини да перете редовно дивана си:
                  </p>,
                  <p key={'pe2'}>
                    Поддържане на хигиена: Редовното пране на дивана помага за
                    отстраняване на мръсотия, прах и насекоми, които могат да се
                    задържат в текстила и да предизвикат алергични реакции и
                    здравословни проблеми.
                  </p>,
                  <p key={'pe3'}>
                    Отстраняване на петна: Петна от храни, напитки, мазнини и
                    други вещества с времето могат да се задържат върху дивана.
                    Изпирането на редовни интервали ще ви помогне да отстраните
                    петната и да върнете свежестта на текстила.
                  </p>,
                  <p key={'pe4'}>
                    Премахване на миризми: Диванът може да задържа неприятни
                    миризми от домашни любимци, петна и други източници.
                    Професионалното почистване ще премахне тези миризми и ще
                    осигури по-свежа и приятна атмосфера във вашата домашна
                    среда.
                  </p>,
                  <p key={'pe5'}>
                    Подобряване на външния вид: Редовното пране на дивана
                    възстановява цветовете и осигурява по-чист и привлекателен
                    външен вид на мебелите във вашата стая.
                  </p>,
                  <p key={'pe6'}>
                    Удължаване на живота на дивана: Почистването на дивана
                    премахва замърсяванията и предпазва материалите от износване
                    и деформации. Това ще удължи живота на дивана и ще ви
                    позволи да го ползвате за по-дълго време.
                  </p>,
                  <p key={'pe7'}>
                    Подобряване на здравето: Измиването на дивана отстранява
                    праха, алергените и други вещества, които могат да
                    предизвикат алергии и дихателни проблеми. Това е особено
                    важно, ако имате алергии или астма.
                  </p>,
                  <p key={'pe8'}>
                    Поддръжка на домашната обстановка: Чистият и поддържан диван
                    прави дома ви по-приятен и уютен. Той създава по-приятна
                    атмосфера за вас, вашето семейство и гостите ви.
                  </p>,
                  <p key={'pe9'}>
                    Защита на инвестицията: Диванът е инвестиция, и редовното
                    пране го предпазва от по-големи повреди и замърсявания,
                    които биха могли да изискват скъпи ремонти или замяна на
                    мебелите.
                  </p>,
                  <p key={'pe10'}>
                    Професионално качество на почистване: Професионалните услуги
                    за пране на дивани ще гарантират, че вашата мебел е
                    подложена на най-ефективно и внимателно почистване. Те
                    разполагат със специални инструменти и продукти, които ще
                    осигурят високо качество на работата.
                  </p>,
                  <p key={'pe11'}>
                    Заради вас и вашето благополучие: Чистата и свежа среда в
                    дома ви влияе на вашето настроение, здраве и добробит.
                    Почистването на дивана е важна част от грижата за себе си и
                    вашето обкръжение.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/moeto-cleaning-expo-prikluchenie',
            element: (
              <BlogPost
                title={blogPostMetadata[5].title}
                postDate={blogPostMetadata[5].postDate}
                readInMin={blogPostMetadata[5].readInMin}
                image={blogPostMetadata[5].image}
                to={blogPostMetadata[5].to}
                description={blogPostMetadata[5].description}
                children={[
                  <p key={'pe1'}>
                    Какво е нужно, за да предложиш най-доброто в професионалното
                    почистване? Cleaning Expo ми даде отговора. През октомври
                    имах шанса да се включа в едно от най-значимите събития в
                    индустрията. Прекарах два дни в Познан, Полша, заобиколен от
                    колеги, производители и хора от науката. Рядко човек може да
                    научи нещо, веднага да го види в действие и да го изпробва.
                    Там направих точно това и опитът е безценен – както за мен,
                    така и за клиентите ми.
                  </p>,
                  <h2 key={'he1'}>Срещи и контакти</h2>,
                  <p key={'pe2'}>
                    През двата дни на събитието разговарях с повече хора, от
                    колкото очаквах. Направих си срещи с производители на
                    техника и химия за почистване. Разговарях и с представители
                    на някои фирми за иновации. Това са компании, които още не
                    са утвърдени на пазара, но техните продукти са с потенциал
                    да разтърсят индустрията. <br /> <br />
                    За мен беше важно да погледна нещата от всичките страни и да
                    отсея хората, с които искам да работя занапред. Изпълних
                    основната си цел да намеря най-новите решения в
                    почистването, за да ги предложа на моите клиенти. Винаги съм
                    искал да бъда крачка пред нужните на пазара, така както
                    работят и моите бизнес клиенти.
                  </p>,
                  <h2 key={'he2'}>Запретнахме ръкави</h2>,
                  <p key={'pe3'}>
                    кателство за собствениците на бизнеси. Имах възможност да
                    обсъдя директно с производителите какви са най-добрите
                    начини за работа при почистването им. С други колеги и
                    представители на бранша обсъдихме почистването на конкретни
                    повърхности – например тези от естествен камък. Нещо
                    изключително красиво, но само ако можем да се грижим за него
                    правилно. <br /> <br />
                    Обърнахме много голямо внимание на почистването на бани и
                    тоалетни. Както откъм нови препарати, които да използваме за
                    почистване, така и за все по-актуалните разработки в
                    областта на дезинфектантите. Оказа се, че доста от колегите
                    имат интереси подходи към почистването на санитарните
                    помещения. Обменихме опит и нашите малки тайни. Със
                    сигурност всеки научи по нещо. <br /> <br />
                    Друго нещо, което беше във фокуса на конференцията са
                    килимите. Срещаме ги в много офиси и хотели. Въпреки че се
                    появяват нови и по-добри материали, почистването и
                    поддръжката остават предизви
                  </p>,
                  <h2 key={'he3'}>Нови технологии в действие</h2>,
                  <p key={'pe4'}>
                    Една от най-вълнуващите части на изложението беше тестването
                    на нови машини и технологии. Участвах в демонстрации на
                    различни устройства и почистващи препарати. Бяха
                    демонстрирани дори напълно автоматични системи за
                    почистване, но липсата на човешки контрол често води до
                    компромиси с резултата. <br /> <br />
                    Ето ме например тук, въоръжен подобаващо за измиване на
                    прозорци и утвърждаващ риболовните ми умения – и то
                    едновременно. <br /> <br />
                    <ResponsiveImage
                      src={FishingImg}
                      alt="Мъж почистващ външни прозорци"
                    />
                    <br />
                    Все пак открих технологии, които наистина правят работата
                    по-ефективна. След като се прибрах в България, отделих почти
                    месец, за да тествам всичко научено. Днес вече използвам
                    част от тях в ежедневната си работа.
                  </p>,
                  <h2 key={'he4'}>Какво се промени за мен?</h2>,
                  <p key={'pe5'}>
                    Посещението на Cleaning Expo беше изключително полезно.
                    Научих много, прилагам го на практика и все по-уверено
                    предлагам на клиентите си услуги, които отговарят на
                    световните стандарти. Сега предлагам по-бързи, по-ефективни
                    и устойчиви решения на техните нужди и проблеми. <br />
                    <br />
                    Вече планирам следващите си пътувания и с нетърпение очаквам
                    да споделя новите си открития с вас. Ще продължавам да
                    пътувам и да търся и най-малките детайли, за да се
                    усъвършенствам. Вярвам и, че след първия ми опит, пътеписите
                    ще станат още по-интересни.
                  </p>,
                  <h2 key={'he5'}>А за моите клиенти?</h2>,
                  <p key={'pe6'}>
                    Търсите безупречна чистота и професионализъм, който ви
                    спестява време и усилия? Свържете се с мен. Уверен съм, че
                    мога да ви предложа професионална услуга, на която може да
                    разчитате. <br /> <br />
                    Оставете чистотата на мен – за да имате повече време за
                    големите неща в живота и бизнеса.
                  </p>,
                ]}
              />
            ),
          },
          {
            path: '/blog/cleaning-expo-2024-novite-tendencii',
            element: (
              <BlogPost
                title={blogPostMetadata[6].title}
                postDate={blogPostMetadata[6].postDate}
                readInMin={blogPostMetadata[6].readInMin}
                image={blogPostMetadata[6].image}
                to={blogPostMetadata[6].to}
                description={blogPostMetadata[6].description}
                children={[
                  <p key={'pe1'}>
                    През октомври в Познан, Полша се проведе събитието Cleaning
                    Expo 2024, едно от най-големите и значими тематични
                    изложения. То събра и водещи компании и специалисти от целия
                    сектор на почистването.
                  </p>,
                  <p key={'pe2'}>
                    Сред посетителите на изложението беше и Мартин Желязков.
                  </p>,
                  <p key={'pe3'}>
                    Мартин е собственик на Marty's Cleaning, компания която
                    предлага почистващи услуги в област Варна. Той сподели с нас
                    впечатленията си от това, което е преживял там. Заедно с
                    колегите си от Европа, той се запозна с най-новите тенденции
                    в областта.
                  </p>,
                  <p key={'pe4'}>
                    Първата тема на изложението бяха автоматизираните системи за
                    почистване.
                  </p>,
                  <p key={'pe5'}>
                    Със сигурност някои от тях машините са интересни. Все още
                    обаче сме много далеч от момента, в който можем да поверим
                    чистенето на машините и от това да не ни заболи глава.
                  </p>,
                  <p key={'pe6'}>
                    Част от Cleaning Expo беше посветена на по-екологичните
                    практики и процеси в почистването.
                  </p>,
                  <p key={'pe7'}>
                    Както при всички други индустрии, и в почистването се търсят
                    начини как да бъдем по-отговорни към околната среда.
                  </p>,
                  <p key={'pe8'}>
                    Мартин успява да се включи в голяма част от презентациите и
                    демонстрациите на нови машини и препарати.
                  </p>,
                  <p key={'pe9'}>
                    Той отбелязва, че много от тези иновации не са широко
                    разпространени в България. Имал е възможност да изпробва как
                    работят те. Категоричен е, че част от видяното има място на
                    нашия пазар и той вече го показва. С тези технологии е
                    по-добре както за професионалистите, така и за клиентите,
                    защото качеството на услугите се увеличава.
                  </p>,
                  <p key={'pe10'}>
                    Най-полезният момент от изложението бяха уъркшопите за
                    почистване на по-взискателни настилки.
                  </p>,
                  <p key={'pe11'}>
                    От една страна твърдите повърхности от естествен камък. От
                    другата страна килимите и като цяло текстила. Всяко със
                    своите особености, нужда от точно определени препарати и
                    техники за почистване.
                  </p>,
                  <p key={'pe12'}>
                    Особено в контекста на административни сгради и хотели е
                    важно да се намери точката на баланс, в която увеличения
                    мащаб на работата не води до по-ниско качество на
                    почистването и ускорена амортизация на повърхностите.
                  </p>,
                  <p key={'pe13'}>
                    Друг акцент на събитието беше хигиената в санитарните
                    помещения.
                  </p>,
                  <p key={'pe14'}>
                    Бяха показани много нови почистващи продукти. Вече са
                    налични много формули, които успяват да осигурят силно
                    почистване и дезинфекция, включвайки щадящи околната среда
                    съставки. Ефективното почистване на бани и тоалетни е от
                    ключово значение за общественото здраве. Именно тук нуждата
                    от нови препарати е най-сериозна.
                  </p>,
                  <p key={'pe15'}>
                    Мартин успя да приведе Marty's Cleaning в крак с новите
                    тенденции в бранша.
                  </p>,
                  <p key={'pe15'}>
                    Така може да ги предложи на своите клиенти във Варна и
                    региона. Според него все още съществуват стереотипи за
                    почистването и почистващите услуги. Събитията като това, на
                    което той беше, обаче доказват колко промени и динамика има
                    всъщност в бранша. Неговата мисия е сега да предложи тези
                    съвременни решения за почистване на клиентите си. По този
                    начин може да разбие клишетата – с резултати, които говорят
                    сами по себе си.
                  </p>,
                ]}
              />
            ),
          },
        ],
      },
    ],
  },
])
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
